
import { CustomField } from '@/common/types/client.type';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import TextInput from './text-input.component.vue';
import BooleanInput from './bool-input.component.vue';

@Options({
	name: 'CustomFieldInput',
	components: { TextInput, BooleanInput },
})
export default class CustomFieldInput extends Vue {
	@Prop() customField!: CustomField;
	@Prop({ default: false }) altStyle!: boolean;
	@Prop({ default: 'This field is required' }) errorMsg!: string;
	@Prop({ default: '' }) id!: string;
	response: string | boolean | number | null = null;
	cfError = false;

	get fieldError() {
		return this.cfError;
	}

	set fieldError(v: boolean) {
		if (this.customField.IsRequired) this.cfError = v;
	}

	created() {
		// set response to session answers
		switch (this.customField.ResponseTypeKey) {
			case 1:
				this.response = this.customField.Response_Boolean ?? false;
				break;
			case 2:
			case 4:
				this.response = this.customField.Response_Text ?? null;
				break;
			case 3:
				if (this.customField.Selected_ClientLocationCustomFieldResponseKeys) {
					this.response = this.customField.Selected_ClientLocationCustomFieldResponseKeys[0];
				}
				break;
		}
	}

	validate(): false | CustomField {
		this.cfError = this.customField.IsRequired ? !this.response : false;
		if (!this.cfError) {
			const cf = { ...this.customField };
			switch (this.customField.ResponseTypeKey) {
				case 1:
					cf.Response_Boolean = this.response as boolean;
					break;
				case 2:
				case 4:
					cf.Response_Text = this.response as string;
					break;
				case 3:
					cf.Selected_ClientLocationCustomFieldResponseKeys = [`${this.response}`];
					break;
			}
			return cf;
		}
		return false;
	}
}
