
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'BooleanInput',
	components: {},
	emits: ['onClick'],
})
export default class BooleanInput extends Vue {
	@Prop() label!: string;
	@Prop() value!: boolean;
	@Prop() showError!: boolean;
	@Prop({ default: 'This field is required' }) errorMsg!: string;
	@Prop({ default: false }) altStyle!: boolean;
	@Prop({ default: '' }) id!: string;
	@Prop() description!: string;

	get elementName() {
		return `${this.label.toLowerCase().split(' ').join('-')}-input`;
	}
}
