import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f39ab66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-input flex-col rd-btn-wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "flex-row form-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", { innerHTML: _ctx.label }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: _normalizeClass(["form-rd-btn", { selected: _ctx.status == true }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.status = true))
      }, [
        _createElementVNode("span", null, [
          _createElementVNode("span", {
            class: _normalizeClass({ 'bold btn-text ': _ctx.yesBtn.label })
          }, "Yes", 2),
          _createElementVNode("span", {
            class: _normalizeClass({ 'label-span': _ctx.yesBtn.label })
          }, _toDisplayString(_ctx.yesBtn.label), 3)
        ])
      ], 2),
      _createElementVNode("button", {
        class: _normalizeClass(["form-rd-btn", { selected: _ctx.status == false }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.status = false))
      }, [
        _createElementVNode("span", null, [
          _createElementVNode("span", {
            class: _normalizeClass({ 'bold btn-text': _ctx.noBtn.label })
          }, "No", 2),
          _createElementVNode("span", {
            class: _normalizeClass({ 'label-span': _ctx.noBtn.label })
          }, _toDisplayString(_ctx.noBtn.label), 3)
        ])
      ], 2)
    ])
  ]))
}