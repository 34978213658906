import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["name", "placeholder", "id"]
const _hoisted_3 = { class: "invalid-input-text" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "si-sel-wrapper" }
const _hoisted_6 = ["id"]
const _hoisted_7 = {
  value: null,
  disabled: true,
  selected: ""
}
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "invalid-input-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_boolean_input = _resolveComponent("boolean-input")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_ctx.customField.ResponseTypeKey == 1)
    ? (_openBlock(), _createBlock(_component_boolean_input, {
        key: 0,
        value: _ctx.response,
        altStyle: _ctx.altStyle,
        label: _ctx.customField.CustomFieldName,
        errorMsg: _ctx.errorMsg,
        description: _ctx.customField.CustomFieldDesc,
        onOnClick: _cache[0] || (_cache[0] = ($event: any) => {
			_ctx.response = $event;
			_ctx.fieldError = !$event;
		}),
        showError: _ctx.fieldError,
        id: `customField-${_ctx.id}`
      }, null, 8, ["value", "altStyle", "label", "errorMsg", "description", "showError", "id"]))
    : (_ctx.customField.ResponseTypeKey == 2)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["form-input flex-col", { invalid: _ctx.fieldError }])
        }, [
          _createElementVNode("label", {
            for: `${_ctx.customField.CustomFieldName}-input`
          }, _toDisplayString(_ctx.customField.CustomFieldName), 9, _hoisted_1),
          _withDirectives(_createElementVNode("textarea", {
            rows: "5",
            name: `${_ctx.customField.CustomFieldName}-input`,
            class: _normalizeClass(["si-input si-textarea", { 'si-alt': _ctx.altStyle }]),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.response) = $event)),
            placeholder: _ctx.customField.CustomFieldDesc,
            id: `customField-${_ctx.id}`,
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fieldError = !_ctx.response.length))
          }, null, 42, _hoisted_2), [
            [_vModelText, _ctx.response]
          ]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.errorMsg), 1)
        ], 2))
      : (_ctx.customField.ResponseTypeKey == 3)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["form-input flex-col", { invalid: _ctx.fieldError }])
          }, [
            _createElementVNode("label", {
              for: `${_ctx.customField.CustomFieldName}-input`
            }, _toDisplayString(_ctx.customField.CustomFieldName), 9, _hoisted_4),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("select", {
                class: _normalizeClass(["si-input", { 'si-alt': _ctx.altStyle }]),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.response) = $event)),
                id: `customField-${_ctx.id}`,
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.fieldError = false))
              }, [
                _createElementVNode("option", _hoisted_7, _toDisplayString(_ctx.customField.CustomFieldDesc), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customField.CustomFieldResponses, (res, idx) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: idx,
                    value: res.ClientLocationCustomFieldResponseKey
                  }, _toDisplayString(res.ResponseLabel), 9, _hoisted_8))
                }), 128))
              ], 42, _hoisted_6), [
                [_vModelSelect, _ctx.response]
              ])
            ]),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.errorMsg), 1)
          ], 2))
        : (_ctx.customField.ResponseTypeKey == 4)
          ? (_openBlock(), _createBlock(_component_text_input, {
              key: 3,
              label: _ctx.customField.CustomFieldName,
              modelValue: _ctx.response,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.response) = $event)),
              showError: _ctx.fieldError,
              errorMsg: _ctx.errorMsg,
              onOnBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.fieldError = $event)),
              altStyle: _ctx.altStyle,
              id: `customField-${_ctx.id}`
            }, null, 8, ["label", "modelValue", "showError", "errorMsg", "altStyle", "id"]))
          : _createCommentVNode("", true)
}